import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import get from "lodash/get"

import NwHelmet from "../components/NwHelmet"
import Header from "../components/HeaderSub"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"

import { kpButtonStyle } from "../components/slideshows/KpSlideshow"

import kpLogo from "../images/slideshow/kp/KP_Logo.jpg"
import forwardWhiteIcon from "../images/slideshow/forward-white.svg"
import coverImagePortrSv from "../images/slideshow/kp/NW_KP_Bild_Portr_1_a.jpg"
import coverImagePortrEng from "../images/slideshow/kp/NW_KP_Bild_Portr_1_a_Eng.jpg"
import coverImageLandsSv from "../images/slideshow/kp/NW_KP_Bild_Lands_1_a.jpg"
import coverImageLandsEng from "../images/slideshow/kp/NW_KP_Bild_Lands_1_a_Eng.jpg"
import coverImageLands2 from "../images/slideshow/kp/NW_KP_Bild_Lands_1_b.jpg"

const Wrapper = styled.div`
  min-height: 100%;
  background: #69c;
`

const SlideshowCover = styled.div`
  position: absolute;
  left: 0;
  width: 100%;

  > div img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    width: auto;
    max-width: calc(100% - 6rem);
    height: auto;
    max-height: 100%;
    margin: auto;
  }

  p {
    margin: 0;
    padding: 1.6rem 3.75rem 0;
    text-align: center;
    color: black;
    font-size: 0.875rem;
  }

  @media (orientation: portrait) and (min-width: 768px) and (min-height: 600px) {
    p {
      font-size: 1rem;
    }
  }

  @media (orientation: portrait) {
    top: 3.6rem;
    height: calc(100% - 3.6rem);

    > div {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      flex-direction: column;
      overflow: hidden;
      justify-content: safe center;
      padding-bottom: 8.5rem;
    }

    picture {
      position: relative;
      height: 100%;
      max-height: 55rem;
    }

    p {
      max-width: 40rem;
      margin: 0 auto;
    }
  }

  @media (orientation: landscape) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 10%;
    height: 80%;
    padding-left: 50%;
    padding-right: 8%;

    &::before {
      display: block;
      content: "";
      width: 100%;
      height: 0;
      margin-bottom: 1rem;
      padding-top: 53.9%;
      background: url(${coverImageLands2}) no-repeat;
      background-size: contain;
    }

    > div img {
      max-width: 100%;
    }

    picture {
      position: absolute;
      top: 50%;
      left: 7.5%;
      width: calc(42.5% - 1.5rem);
      height: 100%;
      transform: translateY(-50%);
    }

    p {
      padding: 0 0 0 2.5rem;
      font-size: 0.875rem;
      line-height: 1.4;
    }
  }

  @media (orientation: landscape) {
    p {
      font-size: 0.75rem;
    }
  }
  @media (min-width: 768px) and (min-height: 768px) {
    top: 2rem;
    height: calc(100% - 4rem);
  }
  @media (orientation: landscape) and (min-width: 750px) and (min-height: 380px) {
    p {
      font-size: 0.875rem;
    }
  }
  @media (orientation: landscape) and (min-width: 800px) and (min-height: 420px) {
    p {
      font-size: 1rem;
    }
  }
  @media (orientation: landscape) and (min-width: 900px) and (min-height: 500px) {
    p {
      font-size: 1.2rem;
    }
  }
  @media (orientation: landscape) and (min-width: 1050px) and (min-height: 600px) {
    p {
      font-size: 1.4rem;
    }
  }
  @media (orientation: landscape) and (min-width: 1200px) and (min-height: 700px) {
    p {
      font-size: 1.6rem;
    }
  }
  @media (orientation: landscape) and (min-width: 1400px) and (min-height: 800px) {
    p {
      font-size: 1.8rem;
    }
  }
`

const StartButton = styled(Link)`
  ${kpButtonStyle}
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;

  @media (orientation: portrait) {
    position: absolute;
    z-index: 2;
    bottom: 3.75rem;
    left: 50%;
    transform: translateX(-50%);
    transform-origin: center left;
  }

  @media (orientation: landscape) {
    position: relative;
    left: 1.25rem;
    margin: 1rem auto 0;
    transform-origin: bottom center;
  }

  @media (orientation: portrait) and (min-width: 768px) and (min-height: 620px) {
    transform: scale(1.3) translateX(-50%);
  }

  @media (orientation: landscape) and (min-width: 768px) and (min-height: 600px) {
    margin-top: 3.25rem;
    transform: scale(1.3);
  }

  @media (orientation: portrait) and (min-width: 1100px) and (min-height: 620px) {
    transform: scale(1.6) translateX(-50%);
  }

  @media (orientation: landscape) and (min-width: 1100px) and (min-height: 700px) {
    margin-top: 4rem;
    transform: scale(1.6);
  }
`

export default function KpStartPageTemplate({
  data: { textsYaml },
  pageContext: { lang, pagePath }
}) {
  const intl = useIntl()
  const isEng = lang !== "sv"

  return (
    <Wrapper>
      <NwHelmet
        lang={lang}
        path={pagePath}
        title={intl.formatMessage({ id: "kpSlideshowTitle" })}
        image={{
          url: lang === "sv" ? coverImagePortrSv : coverImagePortrEng,
          alt: intl.formatMessage({ id: "kpSlideshowTitle" }),
          width: 820,
          height: 1100,
        }}
      />
      <Header
        lang={lang}
        room={3}
        color="#69c"
        isTransparent
      />
      <SlideshowCover>
        <div>
          <picture>
            <source media="(orientation: portrait)" srcSet={isEng ? coverImagePortrEng : coverImagePortrSv} />
            <source media="(orientation: landscape)" srcSet={isEng ? coverImageLandsEng : coverImageLandsSv} />
            <img
              src={isEng ? coverImagePortrEng : coverImagePortrSv}
              alt={intl.formatMessage({ id: "kpSlideshowTitle" })}
            />
          </picture>
          <div dangerouslySetInnerHTML={{ __html: get(textsYaml, "text.html") }} />
        </div>
        <StartButton to={`/${lang}/${pagePath}/1`} aria-label={intl.formatMessage({ id: "startSlideshow" })}>
          {intl.formatMessage({ id: "comeAlong" })}<img src={forwardWhiteIcon} alt="" role="none" />
        </StartButton>
      </SlideshowCover>
      <BrowserUpdateMessage />
      <CookieBanner lang={lang} />
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query($lang: String) {
    textsYaml(lang: { eq: $lang }, _id: { eq: "kpSlideshowCoverText" }) {
      text {
        html
      }
    }
  }
`
