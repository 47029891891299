import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import { Link } from "gatsby"

import kpLogo from "../../images/slideshow/kp/KP_Logo.jpg"

export const kpButtonStyle = `
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 9px 16px 9px;
  background: none;
  border-radius: 9999px;
  color: #fff;
  font-size: .9rem;
  font-weight: 600;
  background: linear-gradient(0deg, #5984AD 0%, #A3CCEE 100%);

  &:active {
    background: linear-gradient(0deg, #A3CCEE 0%, #5984AD 100%);
  }

  &:hover,
  &:focus {
    color: white;
  }

  img {
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
    margin-right: -2px;
    width: auto;
    height: .9rem;
  }
`

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: safe center;
  margin-bottom: 3rem;

  .time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: -2rem;
    left: 10%;
    z-index: 2;
    height: 3.1875rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    background: black;
    color: #FFED00;
    font-size: 0.8rem;
    line-height: 1.1;
    text-align: center;
    font-weight: bold;
    transform: rotate(-15deg);

    span {
      display: block;
      font-size: 1.2rem;
    }
  }

  figure,
  figcaption,
  img {
    display: block;
    margin: 0;
  }

  .figure-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 0;
  }

  figure {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: auto;
  }

  img {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    margin: 0 auto;
  }

  picture {
    position: relative;
    height: 100%
  }

  figcaption {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1.875rem;
    width: 14.25rem;
    height: 3.625rem;
    margin: auto;
    padding: 0.625rem 1.5rem;
    border-radius: 1rem;
    color: black;
    text-align: center;
    background: #A3CCEE;
    font-size: 0.9375rem;
    line-height: 1.3;
    font-weight: bold;
  }

  .text {
    width: 100%;
    max-width: 50rem;
    margin: 3.875rem auto 0;
    padding: 0 2rem;

    p {
      margin: 0;
    }
  }

  @media (min-width: 600px) and (min-height: 600px) {
    .text {
      padding: 0 3rem;
      font-size: 1rem;
    }
  }

  @media (orientation: portrait) {
    .figure-wrapper {
      padding-top: 75.5%;
    }
  }

  @media (orientation: landscape) {
    .figure-wrapper {
      padding-top: 56%;
    }
  }
`

const StyledLink = styled(Link)`
  display: inline-block;
  border: 0;
  padding: 9px 16px 9px;
  min-width: 8rem;
  white-space: nowrap;
  background: none;
  border-radius: 9999px;
  color: #fff;
  font-size: .9rem;
  font-weight: 600;
  text-decoration: none;
  background: linear-gradient(0deg, #5984AD 0%, #A3CCEE 100%);

  &:active {
    background: linear-gradient(0deg, #A3CCEE 0%, #5984AD 100%);
  }

  &:hover,
  &:focus {
    color: white;
  }

  img {
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
    margin-right: -2px;
    width: auto;
    height: .9rem;
  }

  > span {
    display: block;
    text-align: center;
  }

  @media (orientation: landscape) {
    margin: 0 1rem;
  }

  @media (orientation: portrait) {
    margin: 0 auto;
  }
`

const LastSlideImage = styled.div`
  position: relative;
  width: 12.8rem;
  height: 12.8rem;
  margin: 0 auto 2rem;
  border: 0.375rem solid #A3CCEE;
  border-radius: 50%;
  background: white;
  text-align: center;

  img {
    position: absolute;
    top: 1.3rem;
    left: 0;
    right: 0;
    width: 3.4375rem;
    height: 3.3125rem;
    margin: auto;
  }

  .inner {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2rem;
    width: 7.5rem;
    margin: auto;
    color: black;
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 1.2;
  }

  p {
    margin: 0 0 1rem;
  }

  .credits p {
    margin-bottom: 0;
  }

  @media (orientation: landscape) {
    margin-bottom: 0;
  }
`

export const getKpImage = ({ day, time, imagePortrait, imageLandscape, imageAltText, caption }) =>
  <Content>
    <div className="figure-wrapper">
      <p className="time">
        {day ? day : ""}
        <span>{time}</span>
      </p>
      <figure>
        <picture>
          <source media="(orientation: portrait) and (max-width: 420px)" srcSet={`${imagePortrait}?nf_resize=fit&w=648`} />
          <source media="(orientation: portrait) and (min-width: 421px)" srcSet={imagePortrait} />
          <source media="(orientation: landscape) and (max-width: 700px)" srcSet={`${imageLandscape}?nf_resize=fit&w=572`} />
          <source media="(orientation: landscape) and (min-width: 701px)" srcSet={imageLandscape} />
          <img src={imageLandscape} alt={imageAltText} />
        </picture>
        <figcaption>{caption}</figcaption>
      </figure>
    </div>
  </Content>

export const KpLastSlideImage = () => {
  const intl = useIntl()

  return (
    <LastSlideImage>
      <img src={kpLogo} alt="KP" />
      <div className="inner">
        <p>{intl.formatMessage({ id: "kpSlideshowCreditsIntro" })}</p>
        <div className="credits">
          <p>{intl.formatMessage({ id: "kpSlideshowCreditsText" })}: Lisen Hönig</p>
          <p>{intl.formatMessage({ id: "kpSlideshowCreditsPhoto" })}: Peter Lydén</p>
        </div>
      </div>
    </LastSlideImage>
  )
}
